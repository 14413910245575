import axios from '@/common/js/request'

export function bzWorkOrderCheck_pageCheck(data){
    const bzWorkOrderCheck_pageCheck = axios({
        url: '/bzWorkOrderCheck/pageCheck',
        method: 'get',
        params:data
    })
    return bzWorkOrderCheck_pageCheck
}
export function bzWorkOrderCheck_pageCheckRecord(data){
    const bzWorkOrderCheck_pageCheckRecord = axios({
        url: '/bzWorkOrderCheck/pageCheckRecord',
        method: 'get',
        params:data
    })
    return bzWorkOrderCheck_pageCheckRecord
}
export function bzWorkOrderCheck_pageDesign(data){
    const bzWorkOrderCheck_pageDesign = axios({
        url: '/bzWorkOrderCheck/pageDesign',
        method: 'get',
        params:data
    })
    return bzWorkOrderCheck_pageDesign
}
export function bzWorkOrderCheck_pageDesignRecord(data){
    const bzWorkOrderCheck_pageDesignRecord = axios({
        url: '/bzWorkOrderCheck/pageDesignRecord',
        method: 'get',
        params:data
    })
    return bzWorkOrderCheck_pageDesignRecord
}
export function bzWorkOrderCheck_checkPass(data){
    const bzWorkOrderCheck_checkPass = axios({
        url: '/bzWorkOrderCheck/checkPass',
        method: 'post',
        data:data
    })
    return bzWorkOrderCheck_checkPass
}
export function bzWorkOrderCheck_checkReject(data){
    const bzWorkOrderCheck_checkReject = axios({
        url: '/bzWorkOrderCheck/checkReject',
        method: 'post',
        data:data
    })
    return bzWorkOrderCheck_checkReject
}

export function bzWorkOrderCheck_uploadDesign(data){
    const bzWorkOrderCheck_uploadDesign = axios({
        url: '/bzWorkOrderCheck/uploadDesign',
        method: 'post',
        data:data
    })
    return bzWorkOrderCheck_uploadDesign
}
export function bzWorkOrderCheck_pageBudget(data){
    const bzWorkOrderCheck_pageBudget = axios({
        url: '/bzWorkOrderCheck/pageBudget',
        method: 'get',
        params:data
    })
    return bzWorkOrderCheck_pageBudget
}
export function bzWorkOrderCheck_pageBudgetRecord(data){
    const bzWorkOrderCheck_pageBudgetRecord = axios({
        url: '/bzWorkOrderCheck/pageBudgetRecord',
        method: 'get',
        params:data
    })
    return bzWorkOrderCheck_pageBudgetRecord
}

export function bzWorkOrderCheck_uploadBudget(data){
    const bzWorkOrderCheck_uploadBudget = axios({
        url: '/bzWorkOrderCheck/uploadBudget',
        method: 'post',
        data:data
    })
    return bzWorkOrderCheck_uploadBudget
}
export function bzWorkOrderCheck_pagePriceChange(data){
    const bzWorkOrderCheck_pagePriceChange = axios({
        url: '/bzWorkOrderCheck/pagePriceChange',
        method: 'get',
        params:data
    })
    return bzWorkOrderCheck_pagePriceChange
}
export function bzWorkOrderCheck_pagePriceChangeRecord(data){
    const bzWorkOrderCheck_pagePriceChangeRecord = axios({
        url: '/bzWorkOrderCheck/pagePriceChangeRecord',
        method: 'get',
        params:data
    })
    return bzWorkOrderCheck_pagePriceChangeRecord
}
export function user_listByPost(data){
    const user_listByPost = axios({
        url: '/user/listByPost',
        method: 'get',
        params:data
    })
    return user_listByPost
}
export function bzWorkOrderCheck_transferLeader(data){
    const bzWorkOrderCheck_transferLeader = axios({
        url: '/bzWorkOrderCheck/transferLeader',
        method: 'post',
        data:data
    })
    return bzWorkOrderCheck_transferLeader
}
export function bzWorkOrderCheck_priceChangeCheckPass(data){
    const bzWorkOrderCheck_priceChangeCheckPass = axios({
        url: '/bzWorkOrderCheck/priceChangeCheckPass',
        method: 'post',
        data:data
    })
    return bzWorkOrderCheck_priceChangeCheckPass
}
export function bzWorkOrderCheck_priceChangeCheckReject(data){
    const bzWorkOrderCheck_priceChangeCheckReject = axios({
        url: '/bzWorkOrderCheck/priceChangeCheckReject',
        method: 'post',
        data:data
    })
    return bzWorkOrderCheck_priceChangeCheckReject
}
export function bzWorkOrderCheck_pagePriceChangeLeader(data){
    const bzWorkOrderCheck_pagePriceChangeLeader = axios({
        url: '/bzWorkOrderCheck/pagePriceChangeLeader',
        method: 'get',
        params:data
    })
    return bzWorkOrderCheck_pagePriceChangeLeader
}
export function bzWorkOrderCheck_pagePriceChangeLeaderRecord(data){
    const bzWorkOrderCheck_pagePriceChangeLeaderRecord = axios({
        url: '/bzWorkOrderCheck/pagePriceChangeLeaderRecord',
        method: 'get',
        params:data
    })
    return bzWorkOrderCheck_pagePriceChangeLeaderRecord
}
export function bzWorkOrderCheck_getBudgetUser(data){
    const bzWorkOrderCheck_getBudgetUser = axios({
        url: '/bzWorkOrderCheck/getBudgetUser',
        method: 'get',
        params:data
    })
    return bzWorkOrderCheck_getBudgetUser
}
export function bzWorkOrderCheck_getDesignUser(data){
    const bzWorkOrderCheck_getDesignUser = axios({
        url: '/bzWorkOrderCheck/getDesignUser',
        method: 'get',
        params:data
    })
    return bzWorkOrderCheck_getDesignUser
}